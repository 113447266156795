/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { getAuthUser } from 'redux/slices/auth/selector'
const requireAnon = (WrappedComponent: React.ComponentType) => {
  // @ts-ignore
  return (props) => {
    const user = useSelector(getAuthUser)
    // checks whether we are on client / browser or server.
    if (typeof window !== 'undefined') {
      const Router = useRouter()

      const accessToken = localStorage.getItem('token')
      if (accessToken) {
        if (user?.nama_panggilan === null) {
          Router.replace('/onboarding')
        } else {
          Router.replace('/home')
        }
        return null
      }

      return <WrappedComponent {...props} />
    }

    // If we are on server, return null
    return null
  }
}

export default requireAnon
