import { useDispatch } from 'react-redux'
import Head from 'next/head'

import { login } from 'redux/slices/auth/thunk'
import Button from 'components/elements/Button'
import config from 'api/config'

import { Balloon, Mountains, Hero, LoginWrapper, Logo, LogoWrapper } from './styles'

const Login = (): JSX.Element => {
  const dispatch = useDispatch()

  const onLogin = async () => {
    const ssoData: any = await popUpLogin()
    dispatch(login(ssoData))
  }

  const popUpLogin = () => {
    const SSOWindow = window.open(
      new URL('/api/auth/login', config).toString(),
      'SSO UI Login',
      'left=50, top=50, width=480, height=480'
    )

    return new Promise(function (resolve, reject) {
      window.addEventListener(
        'message',
        (e) => {
          try {
            const data: SsoData = JSON.parse(e.data)
            resolve(data)
            if (data.token && SSOWindow) {
              SSOWindow.close()
              window.removeEventListener('message', () => null)
            }
          } catch (error) {}
        }
      )
    })
  }

  return (
    <LoginWrapper>
      <Head>
        <title>Login | PMB 2022</title>
      </Head>

      <LogoWrapper>
        <img src="/images/pmb.svg" alt="pmb" />
        <img src="/images/ristek-purple.svg" alt="ristek" />
      </LogoWrapper>

      <Hero>
        <Logo src="/images/pmb.svg" alt="pmb" />
        <Button type="primary" onClick={onLogin}>
          Log In with SSO
        </Button>
      </Hero>

      <Balloon
        src="/images/balloon-top.png"
        className="balloon-top"
        alt="balloon-top"
      />
      <Balloon
        src="/images/balloon-mid.png"
        className="balloon-mid"
        alt="balloon-mid"
      />
      <Balloon
        src="/images/balloon-btm.png"
        className="balloon-btm"
        alt="balloon-btm"
      />

      <Mountains src="/images/bg-mountains.png" alt="bg-mountains" />
    </LoginWrapper>
  )
}

export default Login
