import styled from 'styled-components'

export const LoginWrapper = styled.div.attrs({
  className: 'w-screen h-screen relative flex justify-center items-center'
})`
  background:
  linear-gradient(180deg, #FFD972 0%, rgba(255, 217, 114, 0) 100%), linear-gradient(180deg, rgba(232, 66, 68, 0.71) 26.22%, rgba(232, 66, 68, 0) 82.96%), #FFFFFF;

  overflow-x: hidden;
  overflow-y: auto;
`

export const Hero = styled.div.attrs({
  className: 'flex flex-col justify-center items-center z-20'
})``

export const Logo = styled.img.attrs({
  className: 'mb-5 w-52 md:w-64'
})``

export const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 1rem;
  margin-left: 0.5rem;
  height: min(3.8vw, 45px);
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;

  & img {
    width: 50%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const Balloon = styled.img`
  position: absolute;
  z-index: 10;
  transform: scale(0.6);
  &.balloon-top {
    transform: rotate(5deg) scale(0.6);
    left: -144px;
    top: -144px;
  }
  &.balloon-mid {
    right: -80px;
    top: 40%;
  }
  &.balloon-btm {
    left: 10%;
    top: 72.5%;
  }
  @media (min-width: 1024px) {
    transform: scale(1);
    &.balloon-top {
      transform: scale(1);
      left: 5%;
      top: -150px;
    }
    &.balloon-mid {
      right: 10%;
      top: 20%;
    }
    &.balloon-btm {
      left: 30%;
      top: 55%;
    }
  }
`

export const Mountains = styled.img.attrs({
  className: 'absolute bottom-0 w-full'
})`
  @media (max-width: 576px) {
    transform: scale(1.75);
    bottom: 2rem;
  }
`
